import React, { Component } from "react";
// import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import { KTIcon } from "../../../../../helpers";
import { CompanyUser } from "../../../../models/CompanyUser";

import stores from "../../../../stores";

interface Props {
  selectedUser?: CompanyUser;
}

interface State {
  isLoading: boolean;
}

export default class DeleteUserModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  private refreshData = async () => {
    await stores.companyUserStore.getCompanyUsers();
    await stores.companyUserStore.getCompanyUserInvites();
    await stores.companyUserStore.getCustomUsers();
  };

  private handleConfirmDelete = async (selectedUser: CompanyUser) => {
    this.setState({ isLoading: true });
    try {
      await stores.companyUserStore.deleteSelectedCompanyUser(selectedUser);
      await this.refreshData();
      ReactGA.event({
        category: "company_users",
        action: "delete_company_user",
        label: "close_company_user_delete_button",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      this.setState({ isLoading: false });
      stores.companyUserStore.isCompanyUserDeleteModalOpened = false;
    }
  };

  private handleClose = () => {
    ReactGA.event({
      category: "company_users",
      action: "close_company_user_delete_modal",
      label: "close_company_user_delete_modal_button",
    });
    stores.companyUserStore.isCompanyUserDeleteModalOpened = false;
  };

  render() {
    const { selectedUser } = this.props;
    const { isLoading } = this.state;

    return (
      <>
        <div
          className="modal fade show d-block"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  onClick={this.handleClose}
                  style={{ cursor: "pointer" }}
                >
                  <KTIcon iconName="cross" className="fs-1" />
                </div>
              </div>

              <div className="modal-body">
                Are you sure you want to delete the selected user?
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.handleClose}
                  disabled={isLoading}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    if (selectedUser && !isLoading) {
                      this.handleConfirmDelete(selectedUser);
                    }
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
